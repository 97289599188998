
// Fonts
@import "../css/font-awesome.css";

// own css
@import "../css/main.css";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Toastr
@import "node_modules/toastr/toastr.scss";

// Ekko lightbox
@import "../css/ekko-lightbox.min.css";